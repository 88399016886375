import React from "react"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/book-us/hero"
import Booking from "components/sections/book-us/booking"
import Video from "components/sections/book-us/video"
import Tools from "components/sections/book-us/tools"
import Testimonials from "components/sections/testimonials"
import Trial from "components/common/trial"
import { seoTitleSuffix } from "config"

const PricingPage = ({ data }) => {
  const { frontmatter } = data.bookUs.edges[0].node

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout headerContent={data.header.edges} footerContent={data.footer.edges} lang="fr">
        <SEO
          frontmatter={frontmatter}
          lang="fr"
        />
        <Hero content={data.hero.edges} />
        <Video content={data.video.edges} />
        <Tools content={data.tools.edges} />
        <Testimonials content={data.testimonials.edges} />
        <div className="gradient">

        </div>
      </Layout>
    </GlobalStateProvider>
  )
}


export default PricingPage

export const pageQuery = graphql`
  {
    bookUs: allMdx(filter: { fileAbsolutePath: { regex: "/book-us/fr/book-us/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/book-us/fr/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            logoArrow
            video
            mdxbgImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
          body
        }
      }
    }
    video: allMdx(filter: { fileAbsolutePath: { regex: "/book-us/fr/video/" } }) {
      edges {
        node {
          frontmatter {
            title
            titlePrefix
          }
        }
      }
    }
    tools: allMdx(filter: { fileAbsolutePath: { regex: "/book-us/fr/tools/" } }) {
      edges {
        node {
          frontmatter {
            title
            titlePrefix
          }
          exports {
            shownItems
            tools {
              alt
              logo {
                childImageSharp {
                  gatsbyImageData(width: 120, formats: [WEBP, AVIF], quality: 90)
                }
              }
              logoHover {
                childImageSharp {
                  gatsbyImageData(width: 120, formats: [WEBP, AVIF], quality: 90)
                }
              }
            }
          }
        }
      }
    }
    testimonials: allMdx(filter: { fileAbsolutePath: { regex: "/index/fr/testimonials/" } }) {
      edges {
        node {
          frontmatter {
            title
            jobTitle
            author
            photo {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            text
            buttonText
            textLeftPrefix
            textRightPrefix
            textLeft
            textRight
            bottomText
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
